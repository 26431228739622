if ($("body").hasClass("home")) {
    $("#start, #end").flatpickr({
        mode: "range",
        dateFormat: "d-m-Y",
        // minDate: "today",
        minDate: "01-04",
        monthSelectorType: 'static',
    });

    document.getElementById('booking-form').addEventListener('submit', function (event) {
        event.preventDefault();
        
        const guestValue = document.getElementById('guest').value;
        const dateRangeValue = document.getElementById('start').value;
        const dateRanges = dateRangeValue.split(" to ");
        const locatieValue = document.getElementById('locatie').value;
        // Split the range into individual parts
        // console.log("Start Date:", dateRanges[0]);
        // console.log("End Date:", dateRanges[1]);

        if (currentLocale == 'en') {
            let url = "https://reserveren.rostig.nl/en/book?period%5Bstart_date%5D=" + dateRanges[0] + "&period%5Bend_date%5D=" + dateRanges[1] + "&guest_group%5Badults%5D=" + guestValue + "&tag_ids%5B%5D=" + locatieValue;
            window.open(url, "_blank");
        } else {
            let url = "https://reserveren.rostig.nl/reserveren?period%5Bstart_date%5D=" + dateRanges[0] + "&period%5Bend_date%5D=" + dateRanges[1] + "&guest_group%5Badults%5D=" + guestValue + "&tag_ids%5B%5D=" + locatieValue;
            window.open(url, "_blank");
        }
    });
}