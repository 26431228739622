
/* RR */

// MENU ICON ANIM
const controlit = $(".menu-icon");  
const menuToggle = gsap.timeline({paused:true, reversed:true})
menuToggle 
  .to(' .top', .2, {y:'3'}, 'rotate')
  .to(' .bot', .2, {y:'-6'}, 'rotate')
  .to(' .top', .2, {rotationZ:45, transformOrigin: '50% 50%'}, 'rotate')
  .to(' .bot', .2, {rotationZ:-45, transformOrigin: '50% 50%'}, 'rotate')
 
controlit.click(function () {
  menuToggle.reversed() ? menuToggle.restart() : menuToggle.reverse();
  $('.menu-text').toggleClass('nav-open');

});


$(".nav-item a").on( "click", function() {
  $('.menu-icon').click();
});


// GLIDE JS //
if( $('.glide').length )   {
  new Glide('.multi', {
    type: 'carousel',
    perView: 1,
    autoheight: true,
  }).mount();
}

// ANIMATE ON SCROLL
const imageFx = gsap.utils.toArray('.imagefx');
imageFx.forEach(element => {
  gsap.from(element, {duration: 1, scale: .9, opacity: 0, scrollTrigger: {trigger: '.imagefx', toggleActions: "play none none none", end: "top center" ,scrub: 1}})
});

// FIXES BULLETS
var spans = $(".home ul li p, .blogpost ul li p");
spans.contents().unwrap();


// COUNTDOWN
if (typeof end !== 'undefined') {
    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var timer;
  
    function showRemaining() {
      var now = new Date();
  
      var distance = end - now;
  
      if (distance < 0) {
  
        clearInterval(timer);
        document.getElementById('countdown').innerHTML = '0d 0h 0m 0s';
        $('#notification-bar').addClass('timer-finished');
  
        return;
      }

      if( $('#notification-bar').hasClass('closed')) {
        clearInterval(timer);
        document.getElementById('countdown').innerHTML = '0d 0h 0m 0s';
        $('#notification-bar').addClass('timer-finished');

        return;
      }

      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      var minutes = Math.floor((distance % _hour) / _minute);
      var seconds = Math.floor((distance % _minute) / _second);
  

    if (document.getElementById('countdown')) {
        document.getElementById('countdown').innerHTML = days + 'd ';
        document.getElementById('countdown').innerHTML += hours + 'h ';
        document.getElementById('countdown').innerHTML += minutes + 'm ';
        document.getElementById('countdown').innerHTML += seconds + 's';
    }

    }
    timer = setInterval(showRemaining);
  }
  
    
    // NOTIFICATION BAR
let barHeight = $("#notification-bar").outerHeight();

setTimeout(function() {
    if (!$("#notification-bar").hasClass("timer-finished")) {
        if ($("#notification-bar").hasClass("opened")) {
            $("#navbar").css('top', barHeight);
            $("#mobile-navbar").css('top', barHeight);
            $("#custom-blocks").css('margin-top', '5rem');
        }

        $( "#close-bar" ).click(function() {
            $('#notification-bar').fadeOut();
            $("#navbar").css('top', '0');
            $("#mobile-navbar").css('top', '0');
            $('#notification-bar').toggleClass('opened').toggleClass('closed');
            $("#custom-blocks").css('margin-top', '0');
        });
    } else {
        $('#notification-bar').css('display', 'none')
        $("#navbar").css('top', '0');
        $("#mobile-navbar").css('top', '0');
        $("#custom-blocks").css('margin-top', '0');
    }
},1);

// CAROUSEL
$('.carousel-item:first-child').addClass('active');

// FIX OPSOMMINGEN
var spans = $('.home ul li p, .blocks ul li p, .blogpost ul li p');
spans.contents().unwrap();
