//PAGE ANIMATION
if ($("body").hasClass("over")) {

  gsap.utils.toArray(".sectie").forEach(function (e) {
    gsap.from(e, {
      duration: 1,
      stagger: 1,
      y: 30,
      ease: "power1.out",
      autoAlpha: 0,
      scrollTrigger: e,
    });
  }); 

  gsap.timeline().fromTo(".bestuurslid", {y: 30, autoAlpha: 0}, {y:0, autoAlpha: 1, duration: 1, stagger: .3} )
  
}