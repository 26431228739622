//PAGE ANIMATION
if ($("body").hasClass("nieuws")) {

  gsap.utils.toArray(".content-column .article").forEach(function (e) {
    gsap.from(e, {
      duration: 1,
      stagger: 1,
      y: 30,
      ease: "power1.out",
      autoAlpha: 0,
      scrollTrigger: e,
    });
  }); 
  
}